/* CSS variables. */
:root {
    --PhoneInput-color--focus: #0a58ce;
    --PhoneInputInternationalIconPhone-opacity: 0.8;
    --PhoneInputInternationalIconGlobe-opacity: 0.65;
    --PhoneInputCountrySelect-marginRight: 0.35em;
    --PhoneInputCountrySelectArrow-width: 0.3em;
    --PhoneInputCountrySelectArrow-marginLeft: var(
        --PhoneInputCountrySelect-marginRight
    );
    --PhoneInputCountrySelectArrow-borderWidth: 1px;
    --PhoneInputCountrySelectArrow-opacity: 0.45;
    --PhoneInputCountrySelectArrow-color: inherit;
    --PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
    --PhoneInputCountrySelectArrow-transform: rotate(45deg);
    --PhoneInputCountryFlag-aspectRatio: 1.5;
    --PhoneInputCountryFlag-height: 1em;
    --PhoneInputCountryFlag-borderWidth: 1px;
    --PhoneInputCountryFlag-borderColor: rgba(0, 0, 0, 0.27);
    --PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
    --PhoneInputCountryFlag-backgroundColor--loading: rgba(0, 0, 0, 0.1);
}

.PhoneInput {
    display: flex;
    align-items: center;
    height: 48px;
    border-radius: 8px !important;
    padding: 16px;
    padding-left: 20px;
    border-radius: 0px;
    background-color: #edf2f7;
    outline: 2px solid transparent;
    transition: 0.2s ease all;
}

.PhoneInput:hover {
    background-color: #e2e8f0;
    transition: 0.2s ease all;
}

.PhoneInput:focus-within {
    outline: 2px solid #0a58ce;
    background-color: white;
}

.PhoneInput .PhoneInputInput {
    flex: 1;
    width: 90%;
    height: 30px;
    font-size: 18px;
    font-style: normal;
    font-family: 'Poppins';
    font-weight: normal;
    line-height: 100%;
    font-stretch: normal;
    letter-spacing: normal;
    margin-left: 8px;
    background-color: transparent;
}

.PhoneInputInput::placeholder {
    color: #8f9bb3;
    transition: 0.3s ease;
}

.PhoneInput .PhoneInputCountry {
    display: flex;
    align-items: center;
}

.PhoneInputCountryIcon {
    width: calc(
        var(--PhoneInputCountryFlag-height) *
            var(--PhoneInputCountryFlag-aspectRatio)
    );
    height: var(--PhoneInputCountryFlag-height);
    border-radius: 2px;
}

.PhoneInputCountryIcon--square {
    width: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--border {
    /* Removed `background-color` because when an `<img/>` was still loading
	   it would show a dark gray rectangle. */
    /* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom. */
    background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
    /* Border is added via `box-shadow` because `border` interferes with `width`/`height`. */
    /* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom,
	   so an additional "inset" border is added. */
    box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
            var(--PhoneInputCountryFlag-borderColor),
        inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
            var(--PhoneInputCountryFlag-borderColor);
}

.PhoneInputCountryIconImg {
    /* Fixes weird vertical space above the flag icon. */
    /* https://gitlab.com/catamphetamine/react-phone-number-input/-/issues/7#note_348586559 */
    display: block;
    /* 3rd party <SVG/> flag icons won't stretch if they have `width` and `height`.
	   Also, if an <SVG/> icon's aspect ratio was different, it wouldn't fit too. */
    width: 100%;
    height: 100%;
    border-radius: 2px;
}

.PhoneInputInternationalIconPhone {
    opacity: var(--PhoneInputInternationalIconPhone-opacity);
}

.PhoneInputInternationalIconGlobe {
    opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}

/* Styling native country `<select/>`. */
.PhoneInputCountrySelect {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 40px;
    margin-left: 16px;
    z-index: 1;
    border: 0;
    opacity: 0;
    cursor: pointer;
}

.PhoneInputCountrySelect[disabled] {
    cursor: default;
}

.PhoneInputCountrySelectArrow {
    display: block;
    content: '';
    width: var(--PhoneInputCountrySelectArrow-width);
    height: var(--PhoneInputCountrySelectArrow-width);
    margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
    border-style: solid;
    border-color: var(--PhoneInputCountrySelectArrow-color);
    border-top-width: 0;
    border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
    border-left-width: 0;
    border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
    transform: var(--PhoneInputCountrySelectArrow-transform);
    opacity: var(--PhoneInputCountrySelectArrow-opacity);
}

.PhoneInputCountrySelect:focus
    + .PhoneInputCountryIcon
    + .PhoneInputCountrySelectArrow {
    opacity: 1;
    color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
    box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
            var(--PhoneInputCountryFlag-borderColor--focus),
        inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
            var(--PhoneInputCountryFlag-borderColor--focus);
}

.PhoneInputCountrySelect:focus
    + .PhoneInputCountryIcon
    .PhoneInputInternationalIconGlobe {
    opacity: 1;
    color: var(--PhoneInputCountrySelectArrow-color--focus);
}
