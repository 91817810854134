* :focus {
    outline: 0 !important;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

.google-sign-in {
    width: 100%;
    margin-top: 0px !important;
    margin-bottom: 16px !important;
}

.tiptap-bullet {
    margin-left: 32px;
}

#parent .hidden-child {
    visibility: hidden;
}

#parent:hover .hidden-child {
    visibility: visible;
}
.minibarparent .minibarchild {
    visibility: hidden;
}

.minibarparent:hover .minibarchild {
    visibility: visible;
}

#handyhands:hover {
    cursor: grab;
}
#handyhands:active {
    cursor: grabbing;
}

*:focus {
    box-shadow: none !important;
}

*[data-focus] {
    box-shadow: none !important;
}

.hover {
    cursor: pointer;
}

.badge {
    position: absolute;
    top: 5px;
    right: 9px;
    padding: 5px 5px;
    border-radius: 50%;
    background: #ff3d71;
    color: white;
}

body {
    overscroll-behavior-y: none;
}

.chakra-menu__menu-list {
    box-shadow: 0 8px 16px 0 rgba(56, 96, 165, 0.15) !important;
}

#logo {
    animation: zoom-in-zoom-out 2s ease normal;
    -webkit-animation-fill-mode: forwards;
    border-radius: 4px;
    box-shadow: 0 8px 16px 0 rgba(56, 96, 165, 0.5);
}

@keyframes zoom-in-zoom-out {
    0% {
        transform: scale(0, 0);
    }
    25% {
        transform: scale(4, 4);
    }
    50% {
        transform: scale(1, 1);
    }
    75% {
        transform: scale(4, 4);
    }
    100% {
        transform: scale(0, 0);
    }
}

.office-otter-form:focus {
    background-color: #f6f9fd;
    border-radius: 8px;
}
.office-otter-form-desc:focus {
    background-color: #f6f9fd;
    border-radius: 8px;
}
.office-otter-community-form {
    border-radius: 8px;
    padding: 4px 8px;
    margin: -4px -8px;
}
.office-otter-community-form:focus {
    background-color: #f6f9fd;
    border-radius: 8px;
    padding: 4px 8px;
}

.description-link {
    color: #2243c5;
    text-decoration: underline;
    cursor: pointer;
}

.ProseMirror p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    color: #718096;
    pointer-events: none;
    height: 0;
    font-size: 16px;
}

.react-rotating-text-cursor {
    animation: blinking-cursor 0.8s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s
        infinite;
}

@keyframes blinking-cursor {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
